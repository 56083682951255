@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  
}

:root{
  --fontcolor: rgb(29, 29, 29);
  --primaryColor: rgb(6, 167, 108);
  --gray-font: #515151;
  --font-size:calc(12px + 0.2vw);
  --font-size-logo:calc(1.5vh + 2.5vh);

  
}

.header{
  z-index: 2;
  top: 0px;
        left: 0px;
        display: flex;
        background-color: var(--primaryColor);
        align-items: center;
        justify-content: start;
        position: fixed;
        width: 100%;
        max-height: 80px;
        min-height: 80px;
        padding-left: 25px;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        
}
#header-logo{
  height: 60px;
  
}
/* .container {
 
  display: flex;
  row-gap: 10px;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  padding: 0px 36px;
} */

.main{
  display: flex;
  height: 70%;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.info-col{
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}
.info-col .logo{
  
  display: flex;
  align-items: center;
  margin-top: 30px;
  mix-blend-mode: difference;
}
.info-col .logo>p{
  font-size: 2.8rem;
  font-weight: 800;
  line-height: 1;
  margin-left: 20px;
  color: rgb(207 207 207);
    mix-blend-mode: difference;
}
.info-col .logo>img{
  width: 90px;

}
.info-col .prom-text>p{
  font-size: 2.6rem;
  font-weight: 500;
  /* line-height: 1; */
  padding-left: 12px;
  color:var(--fontcolor);
}
.prom-text{
  display: flex;
  box-sizing: content-box;
}
.prom-text-r{
  overflow: hidden;
  height: 46px;
}
.prom-text-r>span{
  font-size: 2.6rem;
  font-weight: 600;
  /* line-height: 1; */
  color: var(--fontcolor);
}
.promTextClass{
  display: block;
  /* height: 100%; */
  /* padding-left: 20px; */
  /* animation: spin-word 4s infinite; */
}
.description{
  color: var(--fontcolor);
  font-size: larger;
}

#previewImg{
  height: 90%;
}

@keyframes spin-word {

  10% {
    transform: translateY(-12%);
  }
  20% {
    
    transform: translateY(0%);
  }
  30% {
    transform: translateY(-112%);
  }
  40% {
    
    transform: translateY(-100%);
  }
  50% {
    
    transform: translateY(-212%);
  }
  60% {
    
    transform: translateY(-200%);
  }
  70%{
    transform: translateY(-312%);
  }
  80%{
    transform: translateY(-300%);
  }
  90%{
    transform: translateY(-412%);
  }
  100%{
    transform: translateY(-400%);
  }
}


body{
  background-color:#fbfbfb;
}

.main-card{
  transform: translate(50%, 0%);
  width: 50%;
  height: 100%;
  background-color: #ffffff;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;

}



@media screen and (min-width: 767px) and (max-width: 1023px) {
  /* .container header {
    font-size: 50px;
  } */
  .main{
    display: flex;
    height: 88%;
  }
  .info-col{
    display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;
      height: 100%;
  }

  .prom-text-r>span{
    font-size: 1.8rem;
    font-weight: 600;
    /* line-height: 1; */
    color: var(--fontcolor);
  }
  .info-col .prom-text>p{
    font-size: 1.7rem;
    font-weight: 500;
    /* line-height: 1; */
    color:var(--fontcolor);
  }
  .main-card{
    transform: translate(2.5%, 0%);
    width: 95%;
    height: 100%;
    background-color: #ffffff;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
  
  }
}
@media screen and (min-width: 0px) and (max-width: 766px) {


  .main{
    display: flex;
    margin-top: 92px;
    /* height: 88%; */
    flex-direction: column;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }
  .info-col{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    /* height: 100%; */
    margin: 0px;
    align-content: center;
    padding: 0px;
    width: 100%;
  }
  .info-col .logo {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}
  /* .container {
 
    display: flex;
    height: 100%;
    row-gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
     margin-top: 80px; 
    padding: 0px 20px;
    flex-wrap: nowrap;
  }
  .container header {
    font-size: 50px;
  } */
  .prom-text{
    display: flex;
    box-sizing: content-box;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;
    height: 90px;
    justify-content: space-evenly;
  }
  .prom-text-r{
    overflow: hidden;
    height: 1.5rem;
  }

  .prom-text-r>span{
    font-size: 1.5rem;
    font-weight: 600;
    /* line-height: 1; */
    color: var(--fontcolor);
    max-lines: 1;
    line-clamp: 1;
    word-wrap: break-word;
    text-align: center;
  }
  .info-col .prom-text>p{
    height: 1.5rem;
    font-size: 1.5rem;
    font-weight: 500;
    /* line-height: 1; */
    color:var(--fontcolor);
    word-wrap: break-word;
    text-overflow: ellipsis;
    line-clamp: 1;
    text-align: center;

  }
  .promTextClass{
    display: block;
    /* height: 100%; */
    /* padding-left: 0px; */
    /* animation: spin-word 4s infinite; */
  }
  .main-card{
    transform: translate(2.5%, 0%);
    width: 95%;
    height: 100%;
    background-color: #ffffff;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
  
  }
  #previewImg{
    width: 200px;
  }
}


/* https://source.unsplash.com/vZlTg_McCDo/1920x1080 */


.cs .text {
  font-weight: 800;
  font-size: 80px;
  line-height: 1;
  color: #eee;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.footer-container{

  padding: 16px 20px;
  background-color: #515151;
  width: 100%;
  height: fit-content;
  display: flex;
    justify-content: space-around;

}

.footer-container a{
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  width: fit-content;

}
.footer-container h3{
  font-weight: 600;
  margin-bottom: 12px;
  color: #fff;
  text-decoration: none;
  width: fit-content;
}

.row{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  
}


.loader-container {
  
  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: var(--primaryColor) transparent var(--primaryColor) transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
