@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  
}

:root{
  --fontcolor: rgb(29, 29, 29);
  --primaryColor: rgb(36, 202, 99);
  --gray-font: #515151;
  --font-size:calc(12px + 0.2vw);
  --font-size-logo:calc(1.5vh + 2.5vh);

  
}

.header{
  top: 0px;
        left: 0px;
        display: flex;
        background-color: var(--primaryColor);
        align-items: center;
        justify-content: start;
        position: fixed;
        width: 100%;
        max-height: 80px;
        min-height: 80px;
        padding-left: 25px;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        
}
#header-logo{
  height: 60px;
  
}


.cInfo{
    display: flex;
    color: brown;
    width: 100%;
}

.profilePic{
    border-color: var(--primaryColor);
    border-style: solid;
    border-width: 4px;
    border-radius: 12px;
    width: 90px;
    height: 90px;
    object-fit: cover;
    object-position: 0px 0px;
}

.cInfoText{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    justify-content: space-around;
    padding-left: 12px;
}
.cInfoText span{
    color: var(--fontcolor);

    
}
#cit-fullName{
    font-size: 18px;
    font-weight: 700;
    
}
#cit-country{
    font-size: 16px;
    font-weight: 600;
}
#cit-job{
    font-size: 16px;
    font-weight: 600;
}
.aboutMeC{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin-top: 12px;

}
#aboutMe-heading{
    font-size: 20px;
    font-weight: 700;

}