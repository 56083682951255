@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components{
  .aggrementTitles{
    @apply font-semibold sm:text-[26px] text-[18px] mb-4
  }
  .heading{
    @apply font-semibold sm:text-[23px] text-[17px] mb-4
  }
  .subHeading{
    @apply font-semibold sm:text-[19px] text-[16px] mb-4
  }
  .aggrementParagraph{
    @apply font-medium text-[15px] mb-4
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
